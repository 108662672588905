.credit__card {
    width: 300px;
    height: 200px;
    overflow: hidden;

    border-radius: 10px;

    position: relative;

    .delete__icon {
        position: absolute;
        z-index: 1;

        top: 14px;
        right: 10px;

        color: var(--white-color);
        font-size: 20px;
    }

    .card__front {
        display: flex;
        justify-content: flex-end;
        /* align-items: flex-end; */
        flex-direction: column;
        width: 100%;
        gap: 10px;

        padding: 20px;
        height: 100%;

        .card__nubmer {
            color: var(--white-color, #FFF);

            font-size: 20px;
            font-style: normal;
            font-weight: 600;
        }

        .detail__creadit {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .card__name {
                color: var(--white-color, #fff);

                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }


            .expiry {
                display: flex;
                flex-direction: column;

                .exp__month {
                    color: var(--white-color, #fff);

                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }

                .exp__num {
                    color: var(--white-color, #fff);

                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                }
            }
        }
    }



    .card__back {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        padding-top: 10px;

        .cvc {
            padding: 10px;
            width: 100%;
            background-color: #eee;
            color: #000;
            font-size: 22px;
        }
    }

}

.default {
    background-color: #666;
}

.active__payment {
    border: 4px solid var(--primary-color);
}

.visa--back {
    background-color: none;
    background: url('../../../../public/images/visa.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

.mastercard--back {
    background-color: none;
    background: url('../../../../public/images/mastercard.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}