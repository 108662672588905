* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

body,
html {
    width: 100%;
    height: 100%;
}

.popup__page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .pop__up {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 1200px;
        gap: 30px;
        padding: 30px;
        border-radius: 6px;
        background: #FFF;
        box-shadow: 0px 0px 7px 6px rgba(0, 0, 0, 0.12);
        margin: 40px;

        .header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header__title {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            >i {
                font-size: 24px;
            }
        }

        .upload__container {
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            position: relative;
            width: 100% !important;
            min-height: 300px;
            border-radius: 6px;
            border: 3px dashed #155A70;

            >input {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
                z-index: 1;
            }

            .image__container {
                gap: 10px;
                width: 100%;
                max-height: 400px;
                overflow-y: scroll;
                padding: 30px;
                display: flex;
                align-items: center;
                justify-content: start;
                flex-wrap: wrap;

                .image__data {
                    position: relative;
                    z-index: 2;

                    .close__icon {
                        transition: .3s;
                        opacity: 0;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        color: #FFF;
                        font-size: 24px;
                        cursor: pointer;
                        z-index: 2;
                    }
                    
                    .data__container {
                        transition: .3s;
                        opacity: 0;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        position: absolute;
                        inset: 0;
                        align-items: center;
                        justify-content: center;
                      

                        .data {
                            border-radius: 10px;
                            padding: 4px;
                            border-radius: 6px;
                            background: rgba(255, 255, 255, 0.80);
                            z-index: 2;
                            color: #000;
                            font-family: Inter;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                        .data__container .data:hover {
                            white-space: normal; /* Change white-space to normal */
                            overflow: auto; /* Change overflow to auto */
                            width: auto; /* Change width to auto */
                          }
                          
                          
                        .data:nth-child(2) {
                            color: #000;
                            font-family: Inter;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                        }
                    }

                    >img {
                        width: 150px;
                        height: 150px;
                        border-radius: 6px;
                        border: 1px solid #155A70;
                        background: linear-gradient(0deg, rgba(21, 90, 112, 0.40) 0%, rgba(21, 90, 112, 0.40) 100%), lightgray 50% / cover no-repeat;
                        transition: .3s;
                    }
                }
            }

            .image__data:hover .close__icon,
            .image__data:hover .data__container {
                opacity: 1;
            }
            
            .image__container:hover .image__data .data__container::after {
                content: '';
                position: absolute;
                inset: 0 0 4px 0;
                border-radius: 10px;
                background-color: #00000080;
            }

            .image__container::-webkit-scrollbar {
                display: none;
            }

            .upload__name {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                gap: 17px;
                color: #155A70;

                >i {
                    font-size: 50px;
                }

                >span {
                    color: #155A70;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align: center;
                }
            }
        }

        .btn__container {
            display: flex;
            justify-content: end;
            width: 100%;
            gap: 10px;
            .btn {
                border: 0;
                outline: none;
                padding: 18px 22px;
                border-radius: 6px;
                background: #155A70;
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

    }

}
