.order__container {

    /* Layout */

    display: flex;
    align-items: start;
    gap: 20px;

    width: 100%;

    .detail__section,
    .order__section {

        /* Layout */

        display: flex;
        padding: 20px;
        flex-direction: column;
        gap: 20px;

        max-height: 80vh;
        overflow-y: scroll;

        /* Style */

        border-radius: 10px;
        background: var(--background-color);
    }

    .detail__section::-webkit-scrollbar,
    .order__section::-webkit-scrollbar {
        width: 4px;
    }

    .detail__section::-webkit-scrollbar-thumb,
    .order__section::-webkit-scrollbar-thumb {
        background-color: var(--primary-color);
    }

    .order__section {
        min-width: 460px;

        .type__toggler {

            /* Layout */

            width: 100%;

            display: flex;
            align-items: center;
            overflow: hidden;

            min-height: 50px;

            /* Style */

            border-radius: 10px;
            border: 1px solid var(--primary-color);

            .tab {

                /* Layout */

                width: 100%;

                display: flex;
                align-items: center;
                justify-content: center;

                padding: 16px;

                /* Style */

                color: var(--primary-color);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .pre__paid {
                color: #FFF;
                background-color: var(--primary-color);
            }
        }

        .order__div {

            /* Layout */

            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 20px;

            .order__card {

                /* Layout */

                display: flex;
                justify-content: space-between;
                align-items: center;

                gap: 20px;

                padding: 20px;

                /* Style */

                cursor: pointer;

                border-radius: 10px;
                border: 1px solid var(--primary-color);

                .card__content {

                    /* Layout */

                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                }

                .detail__opener {

                    /* Layout */

                    display: flex;
                    align-items: center;
                    gap: 12px;

                    .price__container{
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                    }

                    .forward__arrow {
                        transition: .3s;
                    }
                }
            }


            .order__card:hover {
                .detail__opener {
                    .forward__arrow {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }

    .detail__section {
        width: 100%;

        .detail__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .detail__container.order__detail {
            .left__side {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }

            .right__side {
                display: flex;
                align-items: center;
                gap: 12px;

                .personal__detail {
                    display: flex;
                    align-items: end;
                    flex-direction: column;
                    gap: 6px;
                }

                >img {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .detail__container.price__container {
            flex-direction: column;
            gap: 20px;

            .price__div {
                width: 100%;

                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .detail__container.orders__container {
            width: 100%;

            gap: 20px;
            align-items: start;
            flex-direction: column;

            .product__cards {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;

                .product__card {
                    width: 100%;

                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .product__detail {
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .left__side {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .quantity__detail {
                                display: flex;
                                flex-direction: column;
                                gap: 6px;
                            }
                        }
                    }

                    .product__accessories {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        padding-left: 10vw;
                        width: 100%;

                        .accessory__li {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;

                            .accessory__detail {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                .circle {
                                    width: 8px;
                                    height: 8px;

                                    border-radius: 50%;
                                    background-color: var(--primary-color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .text__light {
        color: var(--primary-light);
        /* font-family: Raleway; */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .text__dark {
        color: var(--primary-color);
        /* font-family: Raleway; */
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .text__normal {
        color: var(--primary-color);
        /* font-family: Raleway; */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .square__img {
        width: 60px;
        height: 60px;
        border-radius: 10px;

        border: 1px solid var(--primary-color);
    }
}

.order__container .border__line {
    border-top: 1px solid var(--primary-color);
}

.order__card.active__card {
    background-color: var(--primary-color);

    .text__normal,
    .text__light,
    .text__dark {
        color: #FFF !important;
    }
}

@media (max-width: 900px) {
    .order__container {
        flex-direction: column;

        .order__section {
            min-width: auto;
            width: 100%;
        }
    }
}


@media (max-width: 600px) {

    .order__container {

        .text__normal,
        .text__dark,
        .text__light {
            font-size: 14px;
        }
    }
}