.delete__icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: rgba(255, 0, 0, 0.7);
    transition: .3s;
    cursor: pointer;
}

.delete__icon:hover {
    transform-origin: center;
    transform: scale(1.2);
    color: blue;
}