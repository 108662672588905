.address__input {
    width: 100%;


    padding: 6px 10px;
    border-radius: 4px;
    border: 1px solid var(--secondary-color);
    background-color: transparent;

    color: var(--secondary-color);
    outline: none;
}

.address__input::placeholder {
    color: var(--secondary-color);
}

.css-1mcnwpj-MuiList-root {
    z-index: 3;

    position: absolute;

    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    max-height: 250px;
    overflow-y: scroll;
}

.MuiList-root.MuiList-padding.css-1mcnwpj-MuiList-root{
    position: absolute;
}

.address__search {
    position: relative;

    .btn {
        position: absolute;

        top: 5px;
        right: 5px;

        transition: .3s;

        border-radius: 4px;
        padding: 2px 4px;
        

        >i {
            font-size: 18px;
        }
    }
}